import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Search from "../components/Search"

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Tick Tock Inc."
        description="Importer and Distributor of General Merchandise in the Greater New York City Area."
      />
      <div className="tabs is-toggle is-centered is-toggle-rounded">
        <ul>
          <li>
            <Link to="/categories/all">All</Link>
          </li>
          <li>
            <Link to="/categories/aluminum">Aluminum</Link>
          </li>
          <li>
            <Link to="/categories/cleaning">Cleaning</Link>
          </li>
          <li>
            <Link to="/categories/foam">Foam</Link>
          </li>
          <li>
            <Link to="/categories/health-and-beauty">Health & Beauty</Link>
          </li>
          <li>
            <Link to="/categories/aid">Medicine & Aid</Link>
          </li>
          <li>
            <Link to="/categories/mops-and-brooms">Mops & Brooms</Link>
          </li>
          <li>
            <Link to="/categories/plastic">Plastic</Link>
          </li>
          <li>
            <Link to="/categories/paper">Paper</Link>
          </li>
          <li>
            <Link to="/categories/stationary">Stationary</Link>
          </li>
          <li>
            <Link to="/categories/misc">Miscellaneous</Link>
          </li>
        </ul>
      </div>

      <Search />
    </Layout>
  )
}

export default Home
